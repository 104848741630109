//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import alerts from '~/graphql/queries/alerts/alerts.gql';
import updateAlertsLastSeen from '~/graphql/mutations/updateAlertsLastSeen.gql';

export default {
  apollo: {
    alerts: {
      query: alerts,
      pollInterval: 60000,
      variables() {
        return {
          stichting: this.$store.state.currentStichting.id,
        };
      },
    },
  },
  props: {
    colorScheme: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    alerts: [],
    open: false,
  }),
  computed: {
    filteredAlerts() {
      return this.alerts.filter(alert => !alert.hidden);
    },
    lastRead() {
      return new Date((this.$auth.user?.alerts_last_seen || 0));
    },
    newAlertsCount() {
      return this.alerts.filter((alert) => {
        return !alert.read && new Date(alert.created_at) > this.lastRead;
      }).length;
    },
  },
  methods: {
    async toggleAlerts() {
      this.open = !this.open;

      if (this.newAlertsCount > 0 && this.open) {
        try {
          await this.$apollo.mutate({
            mutation: updateAlertsLastSeen,
          });

          this.$auth.fetchUser();
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
